import React, { useState, useEffect } from "react";
import styles from "./Reports.module.scss";
import common from "../../../styles/common.module.scss";
import IQA from "../../../navbar/IQA";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import IqaLayout from "../../../layouts/IqaLayout";

function FeedbackReport() {
  const [selectedOption, setSelectedOption] = useState("");
  const [poidValue, setPoidValue] = useState("");
  const [reportData, setReportData] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [processName, setProcessName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [artists, setArtists] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState("");
  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    // Fetch data from the API
    fetch("https://3dstudio.advertflair.com/stats/artist.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setArtists(data.data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleProcessChange = (event) => {
    setProcessName(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handlePoidInputChange = (event) => {
    setPoidValue(event.target.value);
  };

  const handleArtistChange = (e) => {
    setSelectedArtist(e.target.value);
  };

  const handleDownloadCSV = () => {
    if (reportData) {
      // Create headers and data for CSV
      const headers = [
        "Partner ID",
        "QC TYPE",
        "QC BY",
        "ARTIST",
        "Timestamp",
        "QC ROUNDS",
        "OVERALL FEEDBACKS",
        "BUILD ELEMENTS (BDE)",
        "BDE - Backface",
        "BDE - Gaps",
        "BDE - Meshbuild",
        "COLOR (COL)",
        "COL - ColorAB",
        "COL - ColorL",
        "DETAILS (DTL)",
        "DTL - Detailcount",
        "DTL - DetailPlacement",
        "DTL - DetailType",
        "MATERIAL (MAT)",
        "MAT - Metalness",
        "MAT - Pattern Direction",
        "MAT - PatternScale",
        "MAT - PatternType",
        "MAT - Roughness",
        "MATERALCONTD (MTC)",
        "MTC - HeightMap",
        "MTC - Refraction",
        "MTC - Normal Map",
        "MTC - Shader",
        "MTC - Transparency",
        "ORIENTATION (ORN)",
        "ORN - PivotPoint",
        "ORN - StyleGuide",
        "ORN - Orientation",
        "SCALE - (SCL)",
        "SHAPE - (SHP)",
        "COMPLETE REWORK - (CRW)",
      ];

      const csvContent =
        `${headers.join(",")}\n` +
        reportData.data
          .map(
            (item) =>
              `${item.partner_id},${item.qc_type},${item.qc_by},${item.artist},${item.qc_timestamp},` +
              `${item.qc_rounds},${item.overall_feedbacks},${item.build_elements},${item.Backface},` +
              `${item.Gaps},${item.MeshBuild},${item.color},${item.ColorAB},${item.ColorL},${item.details},` +
              `${item.detail_count},${item.detail_placement},${item.detail_type},${item.material},` +
              `${item.Metalness},${item.pattern_direction},${item.pattern_scale},${item.pattern_type},` +
              `${item.roughnesss},${item.materialcontd},${item.height_map},${item.refraction},` +
              `${item.normal_map},${item.shader},${item.transparency},${item.orientation},` +
              `${item.pivot_point},${item.style_guide},${item.orientation},${item.scale},${item.shape},` +
              `${item.complete_rework}`
          )
          .join("\n");

      // Create a download link and trigger the click event
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleGetReport = () => {
    if (!loading) {
      // Check if no request is already in progress
      setLoading(true); // Set loading state to true

      if (selectedOption === "POID") {
        if (!poidValue.trim()) {
          toast.error("Please enter a POID");
          return;
        }
        axios
          .get(
            `https://3dstudio.advertflair.com/report/feedback.php?partner_id=${poidValue}`
          )
          .then((response) => {
            setReportData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching POID report:", error);
          })
          .finally(() => {
            setLoading(false); // Reset loading state whether the request succeeds or fails
          });
      } else if (selectedOption === "PROCESS") {
        let newSubprocess, newSubvalue;

        if (processName === "QC BY") {
          newSubprocess = "qc_by";
          newSubvalue = selectedArtist;
        } else if (processName === "QC TYPE") {
          newSubprocess = "qc_type";
          newSubvalue = selectedType;
        } else if (processName === "ARTIST") {
          newSubprocess = "artist";
          newSubvalue = selectedArtist;
        }

        console.log(newSubprocess);
        console.log(newSubvalue);

        axios
          .get(
            `https://3dstudio.advertflair.com/report/feedback.php?${newSubprocess}=${newSubvalue}&starttime=${startDate}&endtime=${endDate}`
          )
          .then((response) => {
            setReportData(response.data);
          })
          .catch((error) => {
            toast.error("Error fetching PROCESS report. Please try again.");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <IqaLayout>
      <div>
        <Helmet>
          <title>Reports - Production Report Page</title>
          <link rel="icon" href="/assets/logo/favicon.png" />
        </Helmet>

        <div className={styles.container}>
          <div className={styles.title}>
            <p> I want the report by </p>
            <select onChange={handleOptionChange} value={selectedOption}>
              <option value="" defaultChecked>
                Choose here
              </option>
              <option>POID</option>
              <option>PROCESS</option>
            </select>
          </div>

          {selectedOption === "POID" && (
            <div className={styles.inputSection}>
              <input
                type="text"
                placeholder="Please Enter POID"
                value={poidValue}
                onChange={handlePoidInputChange}
              />
              <button className={common.primaryBtn} onClick={handleGetReport}>
                {loading ? "Processing" : "View Report"}
              </button>
              {reportData && (
                <button
                  className={common.secondaryBtn}
                  onClick={handleDownloadCSV}
                >
                  Download as CSV
                </button>
              )}
            </div>
          )}

          {selectedOption === "PROCESS" && (
            <div className={styles.dropdownSection}>
              <select
                className={styles.process}
                onChange={handleProcessChange}
                value={processName}
              >
                <option value="" defaultChecked>
                  Choose here
                </option>
                <option>QC BY</option>
                <option>QC TYPE</option>
                <option>ARTIST</option>
              </select>

              {processName === "QC BY" && (
                <div className={styles.dateSection}>
                  <label htmlFor="">QC Artist</label>
                  <select value={selectedArtist} onChange={handleArtistChange}>
                    <option value="">Select an artist</option>
                    {artists.map((artist) => (
                      <option key={artist.username} value={artist.username}>
                        {`${artist.name} - ${artist.emp_id}`}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {processName === "ARTIST" && (
                <div className={styles.dateSection}>
                  <label htmlFor="">3D Artist</label>
                  <select value={selectedArtist} onChange={handleArtistChange}>
                    <option value="">Select an artist</option>
                    {artists.map((artist) => (
                      <option key={artist.username} value={artist.username}>
                        {`${artist.name} - ${artist.emp_id}`}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {processName === "QC TYPE" && (
                <div className={styles.dateSection}>
                  <label htmlFor="">QC TYPE</label>
                  <select value={selectedType} onChange={handleTypeChange}>
                    <option value="">Select a QC Type</option>
                    <option>IQA</option>
                    <option value={"FR"}>FINALREVIEW</option>
                  </select>
                </div>
              )}

              <div className={styles.dateSection}>
                <label htmlFor="">Start Date</label>
                <input
                  type="date"
                  onChange={handleStartDateChange}
                  value={startDate}
                  placeholder="Choose Start Date"
                />
              </div>

              <div className={styles.dateSection}>
                <label htmlFor="">End Date</label>
                <input
                  type="date"
                  onChange={handleEndDateChange}
                  value={endDate}
                  placeholder="Choose End Date"
                />
              </div>

              <button className={common.primaryBtn} onClick={handleGetReport}>
                {loading ? "Processing" : "View Report"}
              </button>
              <button
                className={common.secondaryBtn}
                onClick={handleDownloadCSV}
              >
                Download as CSV
              </button>
            </div>
          )}

          {reportData && (
            <div className={styles.report}>
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>POID</th>
                    <th>QC TYPE</th>
                    <th>QC BY</th>
                    <th>ARTIST</th>
                    <th>Timestamp</th>
                    <th>QC ROUNDS</th>
                    <th>OVERALL FEEDBACKS</th>
                    <th>BUILD ELEMENTS (BDE)</th>
                    <th>BDE - Backface</th>
                    <th>BDE - Gaps</th>
                    <th>BDE - Meshbuild</th>
                    <th>COLOR (COL)</th>
                    <th>COL - ColorAB</th>
                    <th>COL - ColorL</th>
                    <th>DETAILS (DTL)</th>
                    <th>DTL - Detailcount</th>
                    <th>DTL - DetailPlacement</th>
                    <th>DTL - DetailType</th>
                    <th>MATERIAL (MAT)</th>
                    <th>MAT - Metalness</th>
                    <th>MAT - Pattern Direction</th>
                    <th>MAT - PatternScale</th>
                    <th>MAT - PatternType</th>
                    <th>MAT - Roughness</th>
                    <th>MATERALCONTD (MTC)</th>
                    <th>MTC - HeightMap</th>
                    <th>MTC - Refraction</th>
                    <th>MTC - Normal Map</th>
                    <th>MTC - Shader</th>
                    <th>MTC - Transparency</th>
                    <th>ORIENTATION (ORN)</th>
                    <th>ORN - PivotPoint</th>
                    <th>ORN - StyleGuide</th>
                    <th>ORN - Orientation</th>
                    <th>SCALE - (SCL)</th>
                    <th>SHAPE - (SHP)</th>
                    <th>COMPLETE REWORK - (CRW)</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.partner_id}</td>
                      <td>{item.qc_type}</td>
                      <td>{item.qc_by}</td>
                      <td>{item.artist}</td>
                      <td>{item.qc_timestamp}</td>
                      <td>{item.qc_rounds}</td>
                      <td>{item.overall_feedbacks}</td>
                      <td>{item.build_elements}</td>
                      <td>{item.Backface}</td>
                      <td>{item.Gaps}</td>
                      <td>{item.MeshBuild}</td>
                      <td>{item.color}</td>
                      <td>{item.ColorAB}</td>
                      <td>{item.ColorL}</td>
                      <td>{item.details}</td>
                      <td>{item.detail_count}</td>
                      <td>{item.detail_placement}</td>
                      <td>{item.detail_type}</td>
                      <td>{item.material}</td>
                      <td>{item.Metalness}</td>
                      <td>{item.pattern_direction}</td>
                      <td>{item.pattern_scale}</td>
                      <td>{item.pattern_type}</td>
                      <td>{item.roughnesss}</td>
                      <td>{item.materialcontd}</td>
                      <td>{item.height_map}</td>
                      <td>{item.refraction}</td>
                      <td>{item.normal_map}</td>
                      <td>{item.shader}</td>
                      <td>{item.transparency}</td>
                      <td>{item.orientation}</td>
                      <td>{item.pivot_point}</td>
                      <td>{item.style_guide}</td>
                      <td>{item.orientation}</td>
                      <td>{item.scale}</td>
                      <td>{item.shape}</td>
                      <td>{item.complete_rework}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    </IqaLayout>
  );
}

export default FeedbackReport;
