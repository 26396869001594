import React from 'react'
import styles from './styles.module.scss'
function LogoutNull() {
    const redirectToHome = () => {
        window.location.href = 'https://54.148.252.184';
    };
  return (
    <div className={styles.container}>

        <div className={styles.leftSection}>
            <img src="/assets/images/Secured.webp" alt="" />
        </div>

        <div className={styles.rightSection}>
            <div className={styles.textSection}>
                <h1>Login Denied</h1>
                <p>You didn't log out yesterday. Please reach out to the manager for access.</p>
            </div>
            <button onClick={redirectToHome}>Go to Home</button>

        </div>
    </div>
  )
}

export default LogoutNull
