export const navigateBasedOnTeam = (team, navigate) => {
  if (
    team === "FIT" ||
    team === "FOX" ||
    team === "WOW" ||
    team === "ZEN" ||
    team === "ZEN"
  ) {
    navigate("/artist-home");
  } else if (team === "IQA" || team === "IQA LEAD") {
    navigate("/list-jobs/iqa");
  } else if (team === "ADMIN-MANAGER" || team === "IT" || team === "3D Lead" || team === "ADMIN") {
    navigate("/list-jobs/iqa");
    console.log("Welcome to admin");
  } else {
    // Handle other cases if needed
  }
};
