import React,{useState} from 'react'
import styles from './LoginPage.module.scss';
import common from '../../../styles/common.module.scss';
import { ToastContainer,toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { navigateBasedOnTeam } from './utils';
import moment from 'moment-timezone';


function Earlylogin() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = location.state && location.state.user;
    const [reason, setReason] = useState('');

    const istTimezone = 'Asia/Kolkata';
    const currentDateInIST = moment().tz(istTimezone);
    const expirationTime = currentDateInIST.add(1, 'days').endOf('day');
    const expirationTimeFormatted = expirationTime.format('YYYY-MM-DD HH:mm:ss');


    const handleLogin = async () => {
        if (!reason) {
            toast.error('Enter reason to procced.');
            return;
        }


        // Assuming user and user details are available in your component state
        const { emp_id, role, team, name, login_time, shift_time, shift_code, username,qc_team } = user;

        // Prepare the data to be sent in the request body
        const postData = {
            emp_id,
            role,
            name,
            team,
            login_time,
            shift_time,
            shift_code,
            username,
            reason: reason,
            qc_team,
        };

        try {
            const response = await axios.post('https://54.148.252.184/auth/early-login.php', postData, {
            headers: {
                'Content-Type': 'application/json',
            },
            });

            const success = response.data.success;
            const message = response.data.message;
            const data = response.data.data;
            if(success){
                const user = {
                    username: data.username,
                    name: data.name,
                    emp_id: data.emp_id,
                    role: data.role,
                    team: data.team,
                    shift_code: data.shift_code,
                    shift_time: data.shift_time,
                    login_time: data.login_time,
                    qc_team:data.qc_team
                };

                localStorage.setItem('user', JSON.stringify(user));
                const team = user.team;

                document.cookie = `username=${user.username}; expires=${expirationTimeFormatted}; path=/`;
                document.cookie = `role=${user.role}; expires=${expirationTimeFormatted}; path=/`;
                document.cookie = `team=${user.team}; expires=${expirationTimeFormatted}; path=/`;
                document.cookie = `qc_team=${user.qc_team}; expires=${expirationTimeFormatted}; path=/`;
                console.log("Working");
                navigateBasedOnTeam(team, navigate);
            }else{
                toast.error(message);
            }

        } catch (error) {
            toast.error(error);
        }
    };

    return (
    <div className={styles.container}>

        <div className={styles.formContainer}>

            <div className={styles.imageSection}>
                <img src="/advertfavicon.png" alt="" />
                <p>Advertflair</p>
            </div>

            <div className={styles.formSection}>
                <p>Early Login Form</p>
                <p className={styles.subtitle}>You are loggin in before 1 hour of the shift time</p>
                <div className={styles.input}>
                    <p>Employee Details</p>
                    <p className={styles.details}>{user.name} - {user.emp_id}</p>
                </div>

                <div className={styles.input}>
                    <p>Shift Details</p>
                    <p className={styles.details}>{user.shift_code} - {user.shift_time} </p>
                </div>

                <div className={styles.input}>
                    <p>Login Details</p>
                    <p className={styles.details}>You Currently logged on {user.login_time}</p>
                </div>


                <div className={styles.input}>
                    <p>Reason</p>
                    <input type="text" placeholder='Enter the reason for early login' value={reason}
          onChange={(e) => setReason(e.target.value)} />
                </div>

                <div className={styles.buttonSection}>
                    <div className={common.secondaryBtn}>Cancel</div>
                    <div className={common.primaryBtn} onClick={handleLogin} >Login to my Account</div>
                </div>

            </div>
            <ToastContainer />
        </div>

    </div>
  )
}

export default Earlylogin
