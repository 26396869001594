import React, { useEffect, useState } from "react";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { IoIosNotificationsOutline, IoMdArrowDropdown } from "react-icons/io";
import { RiLogoutCircleRLine } from "react-icons/ri";
import axios from "axios";
import Cookies from "js-cookie";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";
function Header() {
  const [userData, setUserData] = useState([""]);
  const [breakSelected, setBreakSelected] = useState("");
  const [isModelopen, setModelopen] = useState(false);
  const baseUrl = "https://3dstudio.advertflair.com";
  const { setIsBreakModalOpen } = useStore();
  const [processing, setProcessing] = useState(false);
  const empId = Cookies.get("emp_id");
  const user = Cookies.get("username");
  const [breakdata, setBreakdata] = useState([]);
  const navigate = useNavigate();

  const breakCompleted = () => {
    alert("Break Already Completed");
  };

  function deleteCookies(cookieNames) {
    cookieNames.forEach((name) => {
      Cookies.remove(name);
    });
  }

  useEffect(() => {
    axios
      .get(`https://3dstudio.advertflair.com/auth/profile.php?artist=${user}`)
      .then((response) => {
        const success = response.data.success;
        const data = response.data.data;
        if (success) {
          setUserData(data);
        }
        // Handle the response and set the user data in state
        // Assuming the API response has a 'data' property
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://www.api.advflr.com/studio/common/log_details.php?username=${user}`
      )
      .then((response) => {
        const data = response.data.data;
        setBreakdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const logoutClicked = async () => {
    const formData = { empId };
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://www.api.advflr.com/studio/common/auth/logout.php",
        formData
      );
      const data = response.data;
      if (data.error) {
        alert(data.error);
        setBreakSelected("");
      } else {
        const cookiesToDelete = [
          "username",
          "emp_id",
          "role",
          "team",
          "qc_team",
        ];
        deleteCookies(cookiesToDelete);
        localStorage.clear();
        navigate("/");
        console.log("Updated Successfully");
      }
    } catch (err) {
      setBreakSelected("");
      alert(err);
    } finally {
      setProcessing(false);
      setBreakSelected("");
    }
    setModelopen(false);
  };

  const break1Clicked = async () => {
    const formData = { empId, breakType: "Break 1" };
    setProcessing(true);
    setBreakSelected("Break 1");
    try {
      const response = await axios.post(
        "https://www.api.advflr.com/studio/common/breaks/break_in.php",
        formData
      );
      const data = response.data;
      if (data.error) {
        alert(data.error);
        setBreakSelected("");
      } else {
        console.log("Updated Successfully");
      }
    } catch (err) {
      setBreakSelected("");
      alert(err);
    } finally {
      setProcessing(false);
      setBreakSelected("");
    }
    setIsBreakModalOpen(true);
    setModelopen(false);
  };

  const break2Clicked = async () => {
    const formData = { empId, breakType: "Break 2" };
    setProcessing(true);
    setBreakSelected("Break 2");
    try {
      const response = await axios.post(
        "https://www.api.advflr.com/studio/common/breaks/break_in.php",
        formData
      );
      const data = response.data;
      if (data.error) {
        alert(data.error);
        setBreakSelected("");
      } else {
        console.log("Updated Successfully");
      }
    } catch (err) {
      setBreakSelected("");
      alert(err);
    } finally {
      setProcessing(false);
      setBreakSelected("");
    }
    setIsBreakModalOpen(true);
    setModelopen(false);
  };

  const lunchClicked = async () => {
    const formData = { empId, breakType: "Lunch" };
    setProcessing(true);
    setBreakSelected("Lunch");
    try {
      const response = await axios.post(
        "https://www.api.advflr.com/studio/common/breaks/break_in.php",
        formData
      );
      const data = response.data;
      if (data.error) {
        alert(data.error);
        setBreakSelected("");
      } else {
        console.log("Updated Successfully");
      }
    } catch (err) {
      setBreakSelected("");
      alert(err);
    } finally {
      setProcessing(false);
      setBreakSelected("");
    }
    setIsBreakModalOpen(true);
    setModelopen(false);
  };

  const profileClicked = () => {
    setModelopen(!isModelopen);
  };

  return (
    <>
      <div className="flex justify-between items-center px-3 py-2 bg-[F1F0F8]">
        <div>
          <input
            className="px-3 py-2 rounded-md outline-none"
            type="text"
            placeholder="Search Here"
          />
        </div>
        <div className="flex gap-3 items-center ">
          <div className="flex items-center justify-center px-[8px] py-[8px] rounded-full bg-white">
            <IoChatbubbleEllipsesOutline className="text-xl font-bold" />
          </div>
          <div className="flex items-center justify-center px-[8px] py-[8px] rounded-full bg-white">
            <IoIosNotificationsOutline className="text-xl font-bold" />
          </div>
          <div
            onClick={profileClicked}
            className="flex gap-1 items-center rounded-md hover:bg-white hover:shadow-md px-2 py-1 cursor-pointer"
          >
            <img
              className="w-8 h-8 rounded-full"
              src={`${baseUrl}${userData.user_pic}`}
              alt=""
            />
            <IoMdArrowDropdown />
          </div>
        </div>
      </div>

      {isModelopen && (
        <div
          className={`absolute top-20 shadow-lg right-4 rounded-md z-10 h-56 w-[260px]  border-white bg-[#121239] transition-all duration-300 ease-in-out ${
            isModelopen ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex flex-col gap-7 p-6">
            <div className="flex gap-2 items-center ">
              <img
                className="w-8 h-8 rounded-full"
                src={`${baseUrl}${userData.user_pic}`}
                alt=""
              />
              <div className="flex flex-col gap-[2px]">
                <p className="text-white text-sm font-bold w-28 whitespace-nowrap text-ellipsis overflow-hidden">
                  {userData.name}
                </p>
                <p className="text-white/40 text-xs font-medium w-28 whitespace-nowrap text-ellipsis overflow-hidden">
                  {userData.designation}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="text-white/40 text-sm font-bold">Set Status</div>
              <div className="flex gap-2 w-70 whitespace-nowrap overflow-auto">
                <button
                  disabled={processing}
                  className={` ${
                    breakdata.break1_end === null
                      ? "secondary"
                      : "secondaryDisabled"
                  }`}
                  onClick={
                    breakdata.break1_end === null
                      ? break1Clicked
                      : breakCompleted
                  }
                >
                  {breakSelected === "Break 1" ? "On Process" : "Break 1"}
                </button>
                <button
                  disabled={processing}
                  className={` ${
                    breakdata.lunch_end === null
                      ? "secondary"
                      : "secondaryDisabled"
                  }`}
                  onClick={
                    breakdata.lunch_end === null ? lunchClicked : breakCompleted
                  }
                >
                  {breakSelected === "Lunch" ? "On Process" : "Lunch"}
                </button>
                <button
                  disabled={processing}
                  className={` ${
                    breakdata.break2_end === null
                      ? "secondary"
                      : "secondaryDisabled"
                  }`}
                  onClick={
                    breakdata.break2_end === null
                      ? break2Clicked
                      : breakCompleted
                  }
                >
                  {breakSelected === "Break 2" ? "On Process" : "Break 2"}
                </button>
                <button disabled={processing} className="secondary">
                  Meeting
                </button>
              </div>
            </div>

            <div
              className="flex gap-2 items-center hover:cursor-pointer"
              onClick={logoutClicked}
            >
              <RiLogoutCircleRLine className="text-white/80" />
              <p className="text-white/80 text-sm font-bold">Logout</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
