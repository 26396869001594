import { create } from "zustand";

// Define the store
const useStore = create((set) => ({
  selectedMenu: "home",
  breakType: "",
  breakTime: 0,
  isBreakModalOpen: false,
  setSelectedMenu: (value) => set({ selectedMenu: value }),
  setIsBreakModalOpen: (value) => set({ isBreakModalOpen: value }),
  setBreakType: (value) => set({ breakType: value }),
  setBreakTime: (value) => set({ breakTime: value }),
  resetBreak: () => set({ breakType: "", breakTime: 0 }),
}));

export default useStore;
