import React, { useEffect, useState } from "react";
import useStore from "../../store";
import axios from "axios";
import Cookies from "js-cookie";

function BreakModel() {
  const { isBreakModalOpen, setIsBreakModalOpen } = useStore();
  const [data, setData] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [processing, setProcessing] = useState(false);

  const empId = Cookies.get("emp_id");

  useEffect(() => {
    const startTime = new Date(data.last_breaktime).getTime(); // Convert breakTime to milliseconds
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - startTime;
      const elapsedSeconds = Math.floor(timeDiff / 1000);
      setElapsedTime(elapsedSeconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [data.last_breaktime]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `https://www.api.advflr.com/studio/common/user_details.php?empId=${empId}`
      );
      const data = await response.json();
      console.log(data.data);
      setData(data.data);

      if (data.data.is_break === 1) {
        setIsBreakModalOpen(true);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (isBreakModalOpen) {
      fetchUserData();
    }
  }, [isBreakModalOpen]);

  const endBreak = async () => {
    const empId = Cookies.get("emp_id");
    const formData = { empId, timeElapsed: formatTime(elapsedTime) };
    setProcessing(true);
    try {
      const response = await axios.post(
        "https://www.api.advflr.com/studio/common/breaks/break_end.php",
        formData
      );
      const data = response.data;
      if (data.error) {
        alert(data.error);
      } else {
        console.log("Break Completed Sucessfully");
      }
    } catch (err) {
      alert(err);
    } finally {
      setProcessing(false);
      setIsBreakModalOpen(false);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        isBreakModalOpen ? "visible black/20" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white/10 w-[100%] h-full flex items-center justify-center overflow-auto absolute right-0  shadow p-6 transition-all backdrop-blur-[2px] : ${
          isBreakModalOpen
            ? "translate-x opacity-100"
            : "-translate-x-full opacity-0"
        }`}
      >
        <div className="flex flex-col  justify-center gap-4 w-[512px] bg-white  border-[1px] border-black shadow-lg p-6 rounded-lg">
          <div className="font-bold">
            Employee :{" "}
            <span className="capitalize font-light">{data.name}</span>
          </div>
          <div className="font-bold">
            Break Type :{" "}
            <span className="capitalize font-light">{data.break_type}</span>
          </div>
          <div className="font-bold">
            Break Started :{" "}
            <span className="capitalize font-light">{data.last_breaktime}</span>
          </div>
          <div className="font-bold">
            Time Elapsed :{" "}
            <span className="capitalize font-light">
              {formatTime(elapsedTime)}
            </span>
          </div>
          <div className="flex justify-end">
            <button
              onClick={endBreak}
              disabled={processing}
              className="px-3 py-2 bg-[#121239] text-white text-md rounded-md font-bold hover:bg-[#25256d]"
            >
              {processing ? "Processing..." : "End Break"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreakModel;
