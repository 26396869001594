import React, { useState } from 'react';
import styles from './LateLogins.module.scss'
import Lead3d from '../../../navbar/Lead3d/Lead3d'

function LateLogins() {

    const [selectedTable, setSelectedTable] = useState('lateLoginsTable');

    const handleButtonClick = (tableName) => {
        setSelectedTable(tableName);
    };

  return (
    <div>
        <Lead3d />
        <div className={styles.container}>
            <div className={styles.titleSection}>
                Late Login Page
            </div>

            <div className={styles.middleSection}>
                <div className={styles.leftSection}>
                    <div className={styles.buttonSection}>
                        <button className={selectedTable === 'lateLoginsTable' ? styles.selected : ''}
                        onClick={() => handleButtonClick('lateLoginsTable')}>Late Logins - 40 </button>

                        <button
                            className={selectedTable === 'mostLateLoggers' ? styles.selected : ''}
                            onClick={() => handleButtonClick('mostLateLoggers')}
                        >Most Late Loggers - 5 </button>
                    </div>
                    <div className={styles.tableSection}>

                    
                            <table
                                id="lateLoginsTable"
                                style={{ display: selectedTable === 'lateLoginsTable' ? 'table' : 'none' }}
                            >
                            <thead>
                                <tr>
                                    <td>EMP ID</td>
                                    <td>Name</td>
                                    <td>Reportee</td>
                                    <td>Shift Timing</td>
                                    <td>Login Timing</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                
                                    <tr>
                                        <td>3D033</td>
                                        <td>Immanuel A</td>
                                        <td>Praful</td>
                                        <td>09:30:00</td>
                                        <td>10:34:03</td>
                                        
                                        <td><button>Release Login</button></td>
                                    </tr>
                                
                            </tbody>
                        </table>

                        <table
                            id="mostLateLoggers"
                            style={{ display: selectedTable === 'mostLateLoggers' ? 'table' : 'none' }}
                        >
                            <thead>
                                <tr>
                                    <td>EMP ID</td>
                                    <td>Name</td>
                                    <td>Reportee</td>
                                    <td>Month</td>
                                    <td>No of LateLogins</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                
                                    <tr>
                                        <td>3D033</td>
                                        <td>Immanuel A</td>
                                        <td>Praful</td>
                                        <td>March</td>
                                        <td>6</td>                
                                        <td><button>View History</button></td>
                                    </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={styles.rightSection}>
                    <div className={styles.input}>
                        This is form
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default LateLogins

