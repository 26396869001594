import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "./components/Admin";
import LateLogins from "./components/pages/LateLogins_Page/LateLogins";
import LoginPage from "./components/pages/Login_Page/LoginPage";
import IQAComponent from "./components/Process/IQA/IQAComponent";
import FinalReview from "./components/Process/FinalReview/FinalReview";
import Latelogin from "./components/pages/Login_Page/Latelogin";
import Earlylogin from "./components/pages/Login_Page/Earlylogin";
import ListJobs from "./components/Process/ListJobs/ListJobs";
import ListReport from "./components/Process/ListJobs/ListReport";
import ProductionReport from "./components/pages/Reports/ProductionReport";
import FeedbackReport from "./components/pages/Reports/FeedbackReport";
import FilesReport from "./components/pages/Reports/FilesReport";
import LogoutNull from "./components/pages/restrictedpage/LogoutNull";
import Checklist from "./components/Process/IQA/Checklist";
import IqaLayout from "./layouts/IqaLayout";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/reports" element={<ListReport />} />
        <Route path="/reports/production" element={<ProductionReport />} />
        <Route path="/reports/feedback" element={<FeedbackReport />} />
        <Route path="/reports/filestatus" element={<FilesReport />} />

        <Route path="/early-login" element={<Earlylogin />} />
        <Route path="/late-login" element={<Latelogin />} />
        <Route path="/list-jobs" element={<ListJobs />} />
        <Route path="/list-jobs/iqa" element={<IQAComponent />} />
        <Route path="/list-jobs/iqa/checklist" element={<Checklist />} />
        <Route path="/list-jobs/finalreview" element={<FinalReview />} />
        <Route path="/audit/late-logins" element={<LateLogins />} />

        <Route path="/logout-null" element={<LogoutNull />}></Route>
        <Route path="/layout" element={<IqaLayout />}></Route>

        {/* Other routes if any */}
      </Routes>
    </Router>
  );
}

export default App;
