import React from 'react';
import styles from './ListJobs.module.scss';
import IQA from '../../../navbar/IQA';
import { Link } from 'react-router-dom/dist';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

function ListJobs() {
  return (
    
    
    <div>

    <Helmet>
        <title>Advertflair - List Jobs ( CC, Design, IQA, FR )</title>
        <link rel="icon" href="/assets/logo/favicon.png" />
    </Helmet>
        <IQA />
    
    
    <motion.div className={styles.container} 
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{opacity: 0, y: -50}}
    transition={{ duration: .5 }}
    >
        
        <div className={styles.card}>
            CC
        </div>

        <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1}}
            transition={{ duration: 0.2,ease:'easeInOut' }}  
            >
        <div className={styles.card}>
            Design
        </div>
        </motion.div>

        <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1}}
            transition={{ duration: 0.2,ease:'easeInOut' }}  
            >
        <Link to="/list-jobs/iqa">IQA</Link> 
        </motion.div>


        <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1}}
            transition={{ duration: 0.2,ease:'easeInOut' }}  
        >
        <div className={styles.card}>
        <Link to="/list-jobs/finalreview">Final Review</Link> 
        </div>
        </motion.div>
    </motion.div>
    </div>
    )
}

export default ListJobs
