import React, { useState, useEffect } from "react";
import api from "../../../api";
import styles from "./LoginPage.module.scss";
import common from "../../../styles/common.module.scss";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { navigateBasedOnTeam } from "./utils";
import { Helmet } from "react-helmet";

function LoginPage() {
  const [empid, setEmpid] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const istTimezone = "Asia/Kolkata";
  const currentDateInIST = moment().tz(istTimezone);
  const expirationTime = currentDateInIST.add(1, "days").endOf("day");
  const expirationTimeFormatted = expirationTime.format("YYYY-MM-DD HH:mm:ss");

  //Check ALready logged IN :
  useEffect(() => {
    // Check if the username cookie exists and is valid
    const username = document.cookie.replace(
      /(?:(?:^|.*;\s*)username\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    // Check if the username cookie is not empty and valid (add your validation logic here)
    if (username && isValidUsername(username)) {
      // Navigate to "/artist/"
      const team = document.cookie.replace(
        /(?:(?:^|.*;\s*)team\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      navigateBasedOnTeam(team, navigate);
    }
  }, []);

  const isValidUsername = (username) => {
    return true; // Replace with your actual validation
  };

  const handleLogin = async () => {
    if (!empid || !password) {
      toast.error("Enter Employee ID and password to proceed.");
      return;
    }

    try {
      const response = await api.post(
        "https://3dstudio.advertflair.com/auth/login.php",
        {
          empid,
          password,
        }
      );

      const success = response.data.success;
      const message = response.data.message;
      if (success) {
        const data = response.data.data;
        const login_type = response.data.login_type;

        //Fetch Data :
        const user = {
          username: data.username,
          name: data.name,
          emp_id: empid,
          role: data.role,
          team: data.team,
          shift_code: data.shift_code,
          shift_time: data.shift_time,
          login_time: data.login_time,
          qc_team: data.qc_team,
        };
        localStorage.setItem("user", JSON.stringify(user));

        if (login_type === "Already Login" || login_type === "Correct Login") {
          document.cookie = `username=${user.username}; expires=${expirationTimeFormatted}; path=/`;
          document.cookie = `emp_id=${empid}; expires=${expirationTimeFormatted}; path=/`;
          document.cookie = `role=${user.role}; expires=${expirationTimeFormatted}; path=/`;
          document.cookie = `team=${user.team}; expires=${expirationTimeFormatted}; path=/`;
          document.cookie = `qc_team=${user.qc_team}; expires=${expirationTimeFormatted}; path=/`;

          const team = user.team;
          navigateBasedOnTeam(team, navigate);
        } else if (login_type === "Early Login") {
          navigate("/early-login", { state: { user } });
          console.log("Early Logged in ");
        } else if (login_type === "Late Login") {
          console.log("Late Login Dude");
          navigate("/late-login", { state: { user } });
        } else {
        }

        //Early Login
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      // Handle network error or other issues
      console.error("Error during login:", error);
    }
  };

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Advertflair - Login Page</title>
        <link rel="icon" href="/assets/logo/favicon.png" />
      </Helmet>

      <div className={styles.formContainer}>
        <div className={styles.imageSection}>
          <img src="/advertfavicon.png" alt="" />
          <p>Advertflair</p>
        </div>

        <div className={styles.formSection}>
          <p>Signin to your account</p>
          <div className={styles.input}>
            <p>EMP ID</p>
            <input
              type="text"
              placeholder="Enter Your employee ID"
              value={empid}
              onChange={(e) => setEmpid(e.target.value)}
            />
          </div>

          <div className={styles.input}>
            <p>Password</p>
            <input
              type="password"
              placeholder="Enter Your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className={styles.information}>
            <div className={styles.checkSection}>
              <input type="checkbox" name="" id="" />
              <p>Remember Me</p>
            </div>
            <p>Forgot Password</p>
          </div>

          <div className={styles.buttonSection}>
            <div className={common.secondaryBtn}>Signup</div>
            <div className={common.primaryBtn} onClick={handleLogin}>
              Login
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default LoginPage;
