import React from "react";
import styles from "./ListJobs.module.scss";
import IQA from "../../../navbar/IQA";
import { Link } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import IqaLayout from "../../../layouts/IqaLayout";

function ListReport() {
  return (
    <IqaLayout>
      <div>
        <Helmet>
          <title>Advertflair - List Jobs ( CC, Design, IQA, FR )</title>
          <link rel="icon" href="/assets/logo/favicon.png" />
        </Helmet>

        <motion.div
          className={styles.container}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <div className={styles.card}>CC</div>

          <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <div className={styles.card}>
              <Link to="/reports/production">Production Log</Link>
            </div>
          </motion.div>

          <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <Link to="/reports/feedback">Feedbacks Log</Link>
          </motion.div>

          <motion.div
            className={styles.card}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <div className={styles.card}>
              <Link to="/reports/filestatus">Files Report</Link>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </IqaLayout>
  );
}

export default ListReport;
