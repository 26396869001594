import React, { useEffect, useState, useCallback } from "react";
import styles from "./IQA_Component.module.scss";
import common from "../../../styles/common.module.scss";
import { CiCirclePlus } from "react-icons/ci";
import { IoArrowBackSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TbFidgetSpinner } from "react-icons/tb";
import axios from "axios";
import Cookies from "js-cookie";
import { HiDotsVertical } from "react-icons/hi";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import IqaLayout from "../../../layouts/IqaLayout";
import { MdRefresh } from "react-icons/md";
function IQA_Component() {
  const [queueCount, setQueueCount] = useState(0);
  const [queueData, setQueueData] = useState([]);
  const [overallCount, setOverallCount] = useState(0);
  const [overallData, setOverallData] = useState([]);
  const [wipCount, setWipCount] = useState(0);
  const [wipData, setWipData] = useState([]);
  const [parkCount, setParkCount] = useState(0);
  const [parkData, setParkData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentartist, setCurrentartist] = useState([]);
  const [currentpoid, setCurrentpoid] = useState([]);
  const [currentworkflow, setCurrentworkflow] = useState([]);
  const [currentworkflow_type, setCurrentworkflow_type] = useState([]);
  const [currentlot, setCurrentlot] = useState([]);
  const [supportiveData, setSupportiveData] = useState(null);
  const [previousFeedbacksData, setPreviousFeedbacksData] = useState(null);
  const [ispreviousFeedbacks, setIspreviousFeedbacks] = useState(false);
  const [isSupportiveVisible, setIsSupportiveVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(true);
  const [dashboarddata, setDashboarddata] = useState(null);
  const [dashboarddataLoaded, setDashboarddataLoaded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const showLeftSection = () => {
    setIsTableVisible(true);
    console.log("Running Show Function");
  };

  const hideLeftSection = () => {
    setIsTableVisible(false);
    console.log("Running hide Function");
  };

  const fetchDashboardData = async () => {
    try {
      setDashboarddataLoaded(false);
      const response = await axios.get(
        "https://3dstudio.advertflair.com/admin/api/current_weekdata.php"
      );
      setDashboarddata(response.data);
    } catch (err) {
      alert(err);
    } finally {
      setDashboarddataLoaded(true);
    }
  };

  const fetchData = useCallback(() => {
    const user = Cookies.get("username");
    const queueUrl = `https://3dstudio.advertflair.com/jobs/list-jobs.php?status=IQA&iqa_artist=${user}`;
    const overallUrl =
      "https://3dstudio.advertflair.com/jobs/list-jobs.php?status=IQA";
    const wipUrl =
      "https://3dstudio.advertflair.com/jobs/list-jobs.php?status=IQA-WIP";
    const parkUrl =
      "https://3dstudio.advertflair.com/jobs/list-jobs.php?status=IQA-PARK";
    setIsLoading(true);

    const fetchDataFromUrl = (url, setCount, setData) => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setCount(data.count);
          setData(data.data);
        })
        .catch((error) => console.error("Error fetching data:", error))
        .finally(() => setIsLoading(false));
    };

    fetchDataFromUrl(queueUrl, setQueueCount, setQueueData);
    fetchDataFromUrl(overallUrl, setOverallCount, setOverallData);
    fetchDataFromUrl(wipUrl, setWipCount, setWipData);
    fetchDataFromUrl(parkUrl, setParkCount, setParkData);
  }, []);

  const handleToggle = () => {
    setExpanded(!isExpanded);
  };

  const fetchPreviousFeedbacksData = async (partnerId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://3dstudio.advertflair.com/jobs/iqa/previous-feedbacks.php?poid=${partnerId}`
      );
      const data = response.data;

      if (data.success) {
        setPreviousFeedbacksData(data.data);
        setIspreviousFeedbacks(!ispreviousFeedbacks); // Toggle visibility
      } else {
        console.error("Error fetching supportive data:", data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching supportive data:", error);
    }
  };

  const renderFeedbackContent = () => {
    if (!previousFeedbacksData) {
      return <p>Loading...</p>;
    }

    const baseUrl = "https://54.148.252.184"; // Set your base URL here
    return (
      <div className={styles.supportform}>
        {previousFeedbacksData.map((feedback, index) => {
          try {
            // Assuming feedback.rejections is a JSON string, parse it to an object
            const rejections = JSON.parse(feedback.rejections);

            // Iterate through the rejections object to get category and comment
            return (
              <div className={styles.imagesection} key={index}>
                {Object.keys(rejections).map((key) => (
                  <React.Fragment key={key}>
                    <div className={styles.textSection}>
                      <div className={styles.author}>
                        QC Artist - {feedback.qc_by}
                      </div>
                      <div className={styles.rejectionType}>
                        {feedback.qc_type} - {feedback.qc_rounds}
                      </div>
                      <div className={styles.timestamp}>
                        {feedback.qc_timestamp}
                      </div>
                    </div>
                    <img
                      className={styles.feedbackimg}
                      src={`${baseUrl}${rejections[key].image_location}`}
                      alt=""
                    />
                    <p>Category: {rejections[key].category}</p>
                    <p>Comment: {rejections[key].comment}</p>
                  </React.Fragment>
                ))}
              </div>
            );
          } catch (error) {
            console.error("Error parsing rejections:", error);
            return (
              <div className={styles.imagesection} key={index}>
                <p>Error loading feedback data</p>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const fetchSupportiveData = async (partnerId) => {
    try {
      const response = await axios.get(
        `https://3dstudio.advertflair.com/jobs/iqa/process-supportive.php?poid=${partnerId}`
      );
      const data = response.data;

      if (data.success) {
        setSupportiveData(data.files);
        setIsSupportiveVisible(!isSupportiveVisible); // Toggle visibility
      } else {
        console.error("Error fetching supportive data:", data.message);
      }
    } catch (error) {
      console.error("Error fetching supportive data:", error);
    }
  };

  const renderSupportiveContent = () => {
    if (!supportiveData) {
      return <p>Loading...</p>;
    }

    return (
      <div className={styles.supportform}>
        {Object.keys(supportiveData).map((key) => (
          <div className={styles.imagesection} key={key}>
            <img src={supportiveData[key]} alt="" />
            <p>{key}</p>
            <div className={styles.line}></div>
          </div>
        ))}
      </div>
    );
  };

  const processZip = (partnerId) => {
    axios
      .post("https://3dstudio.advertflair.com/jobs/iqa/process-zip.php", {
        poid: partnerId,
      })
      .then((response) => {
        const success = response.data.success;
        const message = response.data.message;
        if (success) {
          const { glb, fbx } = response.data.data;
          setGlbsize(glb);
          setFbxsize(fbx);
        } else {
          toast.error(message);
        }
      });
  };

  const removeZip = (partnerId) => {
    axios
      .post("https://3dstudio.advertflair.com/jobs/iqa/remove-zip.php", {
        poid: partnerId,
      })
      .then((response) => {
        const success = response.data.success;
        const message = response.data.message;
        if (success) {
          console.log(message);
        } else {
          toast.error(message);
        }
      });
  };

  const CurrentjobFunction = () => {
    const user = Cookies.get("username");
    axios
      .get(
        `https://3dstudio.advertflair.com/jobs/my-job.php?artist=${user}&status=IQA-WIP`
      )
      .then((response) => {
        // Check if the API call was successful and has data
        if (response.data.success) {
          const poid = response.data.data[0].partner_id;
          const artist = response.data.data[0].username;
          const lot = response.data.data[0].Lot;
          const workflow = response.data.data[0].workflow;
          const workflow_type = response.data.data[0].workflow_type;

          setPoid(poid);
          setCurrentpoid(poid);
          setCurrentartist(artist);
          setCurrentlot(lot);
          setCurrentworkflow(workflow);
          setCurrentworkflow_type(workflow_type);
          hideLeftSection();
          //Cookie Setup :
          const expirationDate = new Date();
          expirationDate.setHours(23, 59, 0, 0);
          Cookies.set("poid", poid, { expires: expirationDate, path: "/" });
          setQcForm(true);
        } else {
          setQcForm(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const togglechecklist = () => {
    const checklist_url =
      "https://www.iqa.advflr.com/list-jobs/iqa/checklist?poid=" + poid;
    window.open(checklist_url, "_blank");
  };
  const openInNewTab = () => {
    const external_feedback_url =
      "https://3dstudio.advertflair.com/file_manage/?/feedbacks/" + poid + "/";
    window.open(external_feedback_url, "_blank");
  };

  const openInTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null; // Security best practice
  };

  useEffect(() => {
    fetchData();
    CurrentjobFunction();
    fetchDashboardData();
  }, []);

  const handleUpdatedata = () => {
    fetchData();
    CurrentjobFunction();
    fetchDashboardData();
  };

  const handle_referenceDownload = () => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = "https://3dstudio.advertflair.com/reference_updated.php";
    form.target = "_blank";

    // Create an input element to hold the poid value
    const poidInput = document.createElement("input");
    poidInput.type = "hidden";
    poidInput.name = "poid";
    poidInput.value = poid;

    // Append the input element to the form
    form.appendChild(poidInput);

    // Append the form to the document body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [comment, setComment] = useState("");
  const [parkReason, setParkReason] = useState("");
  const [poid, setPoid] = useState("");
  const [glbsize, setGlbsize] = useState("");
  const [fbxsize, setFbxsize] = useState("");
  const [feedbackEntries, setFeedbackEntries] = useState("");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleImageChange = (event) => {
    // Handle file upload and update selectedImage state
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleParkReason = (event) => {
    setParkReason(event.target.value);
  };

  const handleRemoveFeedback = (index) => {
    setFeedbackEntries((prevEntries) =>
      prevEntries.filter((_, i) => i !== index)
    );
  };

  //Download GLB:
  const downloadGlb = (partnerID) => {
    const fileUrl = `https://advertflair-public.s3.us-west-2.amazonaws.com/feedbacks/glb/${partnerID}.glb`;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = `${partnerID}.glb`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //Download FBX
  const downloadFbx = (partnerID) => {
    const fileUrl = `https://3dstudio.advertflair.com/process/qc/${partnerID}/fbx/${partnerID}_LOW.fbx`;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download = `${partnerID}.fbx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  //Resume Function :
  const resumeFunction = (partnerId) => {
    setIsLoading(true);
    const user = Cookies.get("username");
    axios
      .post(
        `https://3dstudio.advertflair.com/jobs/iqa/resume-iqa.php?poid=${partnerId}&artist=${user}`
      )
      .then((response) => {
        // Handle the response if needed
        console.log("POST request success:", response.data);
        const success = response.data.success;
        const message = response.data.message;

        if (success) {
          CurrentjobFunction();
          fetchData();
          setQcForm(true);

          toast.success(message);
          setExpanded(false);
          hideLeftSection();
        } else {
          toast.error(message);
        }
        setIsLoading(false);
      });
  };

  //Park Submit :
  const parkFunction = (partnerId) => {
    setIsLoading(true);
    const user = Cookies.get("username");
    if (!parkReason.trim()) {
      // Display an error toast if parkReason is empty or blank
      toast.error("You must enter a reason for park");
      return;
    }
    axios
      .post(
        `https://3dstudio.advertflair.com/jobs/iqa/park-iqa.php?poid=${partnerId}&artist=${user}&comment=${parkReason}`
      )
      .then((response) => {
        //Handle the response if needed
        const success = response.data.success;
        const message = response.data.message;

        if (success) {
          setShowParkForm(false);
          fetchData();
          setQcForm(false);
          setIsSupportiveVisible(false);
          toast.success(message);
          handleToggle();
          showLeftSection();
          setExpanded(false);
          setParkReason("");
        } else {
          toast.error(message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error during POST request:", error);
      });
  };
  //Approve Function :
  const approveFunction = (partnerId) => {
    setIsLoading(true);
    setButtonDisabled(true);
    const user = Cookies.get("username");
    axios
      .post(
        `https://3dstudio.advertflair.com/jobs/iqa/approve-iqa-2.php?poid=${partnerId}&artist=${user}`
      )
      .then((response) => {
        //Handle the response if needed
        const success = response.data.success;
        const message = response.data.message;

        if (success) {
          setShowParkForm(false);
          fetchData();
          setQcForm(false);
          setIsSupportiveVisible(false);
          setShowApprovalForm(false);
          showLeftSection();
          toast.success(message);
        } else {
          toast.error(message);
        }
        setIsLoading(false);
        setButtonDisabled(false);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error during POST request:", error);
        setIsLoading(false);
        setButtonDisabled(false);
      });
  };

  //Feedback SUbmit :
  const handleFeedbackSubmit = async () => {
    if (!selectedCategory || !selectedImage || !comment) {
      toast.error("Please fill in all fields");
      return;
    }

    const newFeedbackEntry = {
      category: selectedCategory,
      image: selectedImage,
      comment: comment,
    };

    setFeedbackEntries((prevEntries) => [...prevEntries, newFeedbackEntry]);

    // Clear form fields after successful submission
    setSelectedCategory("");
    setSelectedImage(null);
    setComment("");
  };

  const handleFormRender = () => {
    if (!Array.isArray(feedbackEntries)) {
      return null;
    }

    return feedbackEntries.map((entry, index) => (
      <div key={index} className={styles.category_section}>
        <div className={styles.category_section_1}>
          <div className={styles.label_section}>{`Category-${index + 1}`}</div>
          {/* Additional logic for rendering previously submitted data */}
          <div className={styles.image_button}>{entry.category}</div>
        </div>
        <div className={styles.category_section_1}>
          <div className={styles.label_section}>{`Image-${index + 1}`}</div>
          <div className={styles.image_button}>
            {/* Additional logic for rendering previously submitted data */}
            {entry.image && (
              <img
                src={URL.createObjectURL(entry.image)}
                alt={`Image-${index + 1}`}
              />
            )}
          </div>
        </div>
        <div className={styles.category_section_1}>
          <div className={styles.label_section}>{`Comment-${index + 1}`}</div>
          <div className={styles.image_button}>
            {/* Additional logic for rendering previously submitted data */}
            {entry.comment}
          </div>
        </div>
        <div
          className={styles.remove_button}
          onClick={() => handleRemoveFeedback(index)}
        >
          Remove
        </div>
      </div>
    ));
  };

  //Submit Overall Feedbacks :
  const submitAllFeedbacks = async (partnerID) => {
    if (feedbackEntries.length === 0) {
      toast.error("No feedback entries to submit");
      return;
    }

    const userConfirmed = window.confirm(
      "Are you sure you want to submit all feedbacks?"
    );

    if (userConfirmed) {
      setIsLoading(true);

      try {
        const formData = new FormData();
        const user = Cookies.get("username");
        formData.append("user", user);
        formData.append("poid", partnerID);

        feedbackEntries.forEach((entry, index) => {
          formData.append(`categories[${index}]`, entry.category);
          formData.append(`images[${index}]`, entry.image);
          formData.append(`comments[${index}]`, entry.comment);
        });

        const response = await fetch(
          "https://3dstudio.advertflair.com/jobs/iqa/submit-feedbacks.php",
          {
            method: "POST",
            body: formData,
          }
        );

        // Check if the response status is OK (200-299 range)
        if (response.ok) {
          const responseData = await response.json();
          // Handle the API response as needed
          console.log(responseData);
          const data = responseData;
          const success = data.success;
          const message = data.message;
          if (success) {
            setFeedbackEntries([]);
            removeZip(partnerID);
            setShowParkForm(false);
            fetchData();
            setQcForm(false);
            setShowFeedbackForm(false);
            setIsSupportiveVisible(false);
            showLeftSection();
            toast.success(message);
          } else {
            toast.error(message);
          }
        } else {
          // Handle errors here
          console.error("Error submitting feedbacks:", response.statusText);
          toast.error("Error submitting feedbacks");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error submitting feedbacks:", error);
        toast.error("Error submitting feedbacks");
      }
    }
  };

  //Get COunt :

  const [activeTable, setActiveTable] = useState("Finalreview-Table");

  const handleButtonClick = (tableId) => {
    setActiveTable(tableId);
  };

  const [qcForm, setQcForm] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showParkForm, setShowParkForm] = useState(false);
  const [showApprovalForm, setShowApprovalForm] = useState(false);
  const [showViewIconForm, setShowViewIconForm] = useState(false);

  //QC Form:

  const handleBackButtonClick = () => {
    setQcForm(true);
    setShowFeedbackForm(false);
    setShowParkForm(false);
    setShowApprovalForm(false);
    setShowViewIconForm(false);
  };

  const startQueue = (partnerId) => {
    setIsLoading(true);
    const user = Cookies.get("username");
    const apiUrl = `https://3dstudio.advertflair.com/jobs/iqa/take-iqa.php?poid=${partnerId}&qc_artist=${user}`;
    axios.get(apiUrl).then((response) => {
      // Handle the data from the API response
      const success = response.data.success;
      const message = response.data.message;
      if (success) {
        fetchData();
        CurrentjobFunction();

        setQcForm(true);
        toast.success(message);
        setExpanded(true);
        hideLeftSection();
      } else {
        toast.error(message);
      }
      setIsLoading(false);
    });
  };

  const handleAutoAssign = () => {
    const user = Cookies.get("username");
    fetch(
      `https://3dstudio.advertflair.com/jobs/iqa/auto-assign.php?artist=${user}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        if (data.success) {
          const poid = data.poid;
          startQueue(poid);
          fetchData();
          CurrentjobFunction();

          setQcForm(true);
          setExpanded(true);
          hideLeftSection();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        toast.error("Please try again");
      });
  };

  const handleRejectClick = () => {
    setQcForm(false);
    setShowFeedbackForm(true);
    setShowParkForm(false);
    setShowApprovalForm(false);
    setShowViewIconForm(false);
    setIsSupportiveVisible(false);
  };

  const handleParkClick = () => {
    setQcForm(false);
    setShowFeedbackForm(false);
    setShowParkForm(true);
    setShowApprovalForm(false);
    setShowViewIconForm(false);
  };

  const handleApproveClick = () => {
    setQcForm(false);
    setShowFeedbackForm(false);
    setShowParkForm(false);
    setShowApprovalForm(true);
    setShowViewIconForm(false);
  };

  const handleViewIconClick = () => {
    setShowViewIconForm((prevShowViewIconForm) => !prevShowViewIconForm);
  };

  return (
    <IqaLayout>
      <div>
        <Helmet>
          <title>Advertflair - IQA Page</title>
          <link rel="icon" href="/assets/logo/favicon.png" />
        </Helmet>

        <motion.div
          className={styles.container}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          <div
            className={`${styles.left_section_hidden} ${
              isTableVisible ? styles.left_section : ""
            }`}
          >
            <div className="flex justify-between items-center ">
              <p className="text-xl font-bold">Home / IQA</p>

              {dashboarddataLoaded ? (
                <div className="flex gap-2 text-xs sm:text-sm font-medium bg-[#e3e3f0] px-4 py-2 rounded-sm cursor-pointer hover:shadow-md">
                  <div>
                    E2E -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.e2e_pending}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    Primary -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.primary_files}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    Secondary -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.secondary_files}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    Today Approvals -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.today_approved}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    First Passes -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.firstpass_files}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    DTA -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.dta_files}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    EQA1 -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.eqa1_files}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    FPY -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.ftr_percentage}
                    </span>
                  </div>
                  <div className="h-full w-[1px] bg-white"></div>
                  <div>
                    Overall Approvals -{" "}
                    <span className="text-md font-bold">
                      {dashboarddata.approved_files}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="h-9 w-[964px] bg-[#e3e3f0] rounded-md animate-pulse">
                  {" "}
                </div>
              )}
            </div>
            <div className={styles.top_section}>
              <div className="flex gap-1">
                <div
                  className={
                    activeTable === "AutoAssigner"
                      ? common.navButtonActive
                      : common.primaryBtn
                  }
                  onClick={handleAutoAssign}
                >
                  Auto Assign
                </div>
              </div>

              <div
                className={
                  activeTable === "QueueTable"
                    ? common.navButtonActive
                    : common.navButton
                }
                onClick={() => handleButtonClick("QueueTable")}
              >
                My Queue - {queueCount}
              </div>
              <div
                className={
                  activeTable === "OverallTable"
                    ? common.navButtonActive
                    : common.navButton
                }
                onClick={() => handleButtonClick("OverallTable")}
              >
                Overall Queue - {overallCount}
              </div>
              <div
                className={
                  activeTable === "WipTable"
                    ? common.navButtonActive
                    : common.navButton
                }
                onClick={() => handleButtonClick("WipTable")}
              >
                Work In Progress - {wipCount}
              </div>
              <div
                className={
                  activeTable === "ParkTable"
                    ? common.navButtonActive
                    : common.navButton
                }
                onClick={() => handleButtonClick("ParkTable")}
              >
                Parked - {parkCount}
              </div>

              <TbFidgetSpinner
                onClick={handleUpdatedata}
                className={`text-xl font-bold ${
                  isLoading ? "animate-spin" : ""
                } `}
              />
            </div>
            <div className={styles.line}></div>
            <div className={styles.bottom_section}>
              <div id="final_review_form" className={styles.table_section}>
                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{
                    opacity: activeTable === "QueueTable" ? 1 : 0,
                    y: 0,
                  }}
                  exit={{ opacity: 1, y: 50 }}
                  transition={{ duration: 1 }}
                >
                  {activeTable === "QueueTable" && (
                    <table>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Partner ID</th>
                          <th>Artist</th>
                          <th>Lot</th>
                          <th>IQA (Error / Rounds)</th>
                          <th>FR (Error / Rounds)</th>
                          <th>EQA Rounds</th>
                          <th>Last QC</th>
                          <th>IQA Artist</th>
                          <th>FR Artist</th>
                          <th>Uploaded</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {queueData.map((job, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{job.username}</td>
                            <td>
                              <a
                                className={styles.link}
                                target="_blank"
                                href={job.model_link}
                              >
                                {job.partner_id}
                              </a>{" "}
                            </td>
                            <td>{job.Lot}</td>
                            <td>
                              {job.iqa_rounds} / {job.upload_counts - 1}
                            </td>
                            <td>
                              {job.finalreview_status} /{" "}
                              {job.finalreview_rounds}
                            </td>
                            <td>{job.revision_status}</td>
                            <td>{job.lastqc_process}</td>
                            <td>{job.qc_artist}</td>
                            <td>{job.final_review_by}</td>
                            <td>
                              <a className={styles.time}>
                                {job.uploaded_date}{" "}
                              </a>
                            </td>
                            <td>
                              {" "}
                              <div
                                className={common.primaryBtn}
                                onClick={() => startQueue(job.partner_id)}
                              >
                                Take IQA
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{
                    opacity: activeTable === "OverallTable" ? 1 : 0,
                    y: 0,
                  }}
                  exit={{ opacity: 1, y: 50 }}
                  transition={{ duration: 1 }}
                >
                  {activeTable === "OverallTable" && (
                    <table>
                      <thead>
                        <tr>
                          <th>NO</th>
                          <th>Partner ID</th>
                          <th>Artist</th>
                          <th>Lot</th>
                          <th>IQA (Error / Rounds)</th>
                          <th>FR (Error / Rounds)</th>
                          <th>EQA Rounds</th>
                          <th>Last QC</th>
                          <th>IQA Artist</th>
                          <th>FR Artist</th>
                          <th>Uploaded</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {overallData
                          .sort(
                            (a, b) =>
                              new Date(a.uploaded_date) -
                              new Date(b.uploaded_date)
                          ) // Sort by uploaded_date
                          .map((job, index) => (
                            <tr key={index + 1}>
                              <td>{index + 1}</td>
                              <td>
                                <a
                                  className={styles.link}
                                  target="_blank"
                                  href={job.model_link}
                                >
                                  {job.partner_id}
                                </a>{" "}
                              </td>
                              <td>{job.username}</td>
                              <td>{job.Lot}</td>
                              <td>
                                {job.iqa_rounds} / {job.upload_counts - 1}
                              </td>
                              <td>
                                {job.finalreview_status} /{" "}
                                {job.finalreview_rounds}
                              </td>
                              <td>{job.revision_status}</td>
                              <td>{job.lastqc_process}</td>
                              <td>{job.qc_artist}</td>
                              <td>{job.final_review_by}</td>
                              <td>
                                <a className={styles.time}>
                                  {job.uploaded_date}
                                </a>{" "}
                                {/* Displaying the upload time */}
                              </td>
                              <td>
                                <div
                                  className={common.primaryBtn}
                                  onClick={() => startQueue(job.partner_id)}
                                >
                                  Take IQA
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{
                    opacity: activeTable === "WipTable" ? 1 : 0,
                    y: 0,
                  }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 1 }}
                >
                  {activeTable === "WipTable" && (
                    <table>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Artist</th>
                          <th>Partner ID</th>
                          <th>Lot</th>
                          <th>IQA (Error / Rounds)</th>
                          <th>FR (Error / Rounds)</th>
                          <th>EQA Rounds</th>
                          <th>QC By</th>
                          <th>QC Started</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wipData.map((job, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>{job.username}</td>
                            <td>
                              <a
                                className={styles.link}
                                target="_blank"
                                href={job.model_link}
                              >
                                {job.partner_id}
                              </a>{" "}
                            </td>
                            <td>{job.Lot}</td>
                            <td>
                              {job.iqa_rounds} / {job.upload_counts}
                            </td>
                            <td>
                              {job.finalreview_status} /{" "}
                              {job.finalreview_rounds}
                            </td>
                            <td>{job.revision_status}</td>
                            <td>{job.qc_artist}</td>
                            <td>
                              <a className={styles.time}>{job.iqa_taken} </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: -50 }}
                  animate={{
                    opacity: activeTable === "ParkTable" ? 1 : 0,
                    y: 0,
                  }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 1 }}
                >
                  {activeTable === "ParkTable" && (
                    <table>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Partner ID</th>
                          <th>Lot</th>
                          <th>IQA (Error / Rounds)</th>
                          <th>FR (Error / Rounds)</th>
                          <th>EQA Rounds</th>
                          <th>Parked On</th>
                          <th>Park Reason</th>
                          <th>Parked By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {parkData.map((job, index) => (
                          <tr key={index + 1}>
                            <td>{index + 1}</td>
                            <td>
                              <a
                                className={styles.link}
                                target="_blank"
                                href={job.model_link}
                              >
                                {job.partner_id}
                              </a>{" "}
                            </td>
                            <td>{job.Lot}</td>
                            <td>
                              {job.iqa_rounds} / {job.upload_counts}
                            </td>
                            <td>
                              {job.finalreview_status} /{" "}
                              {job.finalreview_rounds}
                            </td>
                            <td>{job.revision_status}</td>
                            <td>
                              <a className={styles.time}>{job.parked_on} </a>
                            </td>
                            <td>{job.parked_reason}</td>
                            <td>{job.parked_by}</td>
                            <td>
                              {" "}
                              <div
                                className={common.primaryBtn}
                                onClick={() => resumeFunction(job.partner_id)}
                              >
                                Resume IQA
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </motion.div>
              </div>
            </div>
          </div>
          <div className={styles.right_section}>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: qcForm ? 1 : 0, y: qcForm ? 0 : -20 }}
              transition={{ duration: 0.5 }}
            >
              {qcForm ? (
                <div id="iqa_form" className={styles.iqa_form}>
                  <div id="view_icon" className={styles.icon_section}>
                    {currentpoid} - Form
                    <HiDotsVertical
                      className={styles.icon}
                      onClick={handleViewIconClick}
                    />
                  </div>
                  <div className={styles.label_section}>
                    POID : {currentpoid}
                  </div>
                  <div className={styles.label_section}>
                    ARTIST : {currentartist}
                  </div>
                  <div className={styles.label_section}>
                    {currentworkflow} - {currentworkflow_type}
                  </div>
                  <div className={styles.label_section}>LOT- {currentlot}</div>
                  <input
                    type="text"
                    value={parkReason}
                    onChange={handleParkReason}
                    placeholder="Enter Your Park Reason"
                  />
                  <div className={styles.button_section}>
                    <div
                      id="reject_button"
                      className={common.secondaryBtn}
                      onClick={handleRejectClick}
                    >
                      Reject
                    </div>
                    <div
                      id="park_button"
                      className={common.secondaryBtn}
                      onClick={handleParkClick}
                    >
                      Park
                    </div>
                    <div
                      id="approve_button"
                      className={styles.approve_button}
                      onClick={handleApproveClick}
                    >
                      Approve
                    </div>
                  </div>
                </div>
              ) : null}
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: qcForm ? 1 : 0, y: qcForm ? 0 : -20 }}
              transition={{ duration: 0.5 }}
            >
              {showViewIconForm && (
                <div id="view_icon_form" className={styles.view_icon_form}>
                  <div
                    className={styles.view_icon_button}
                    onClick={handle_referenceDownload}
                  >
                    View References
                  </div>

                  <div
                    className={styles.view_icon_button}
                    onClick={() =>
                      openInTab(
                        `https://3dstudio.advertflair.com/studio/temp/glb/${currentpoid}.glb`
                      )
                    }
                  >
                    New Glb
                  </div>

                  <div
                    className={styles.view_icon_button}
                    onClick={() =>
                      openInTab(
                        `https://3dstudio.advertflair.com/studio/temp/support/${currentpoid}.zip`
                      )
                    }
                  >
                    Download Supportive
                  </div>
                  <div
                    className={styles.view_icon_button}
                    onClick={() => fetchPreviousFeedbacksData(currentpoid)}
                  >
                    {" "}
                    {ispreviousFeedbacks
                      ? "Hide Feedbacks"
                      : "Show Old Feedbacks"}
                  </div>
                  <div
                    className={styles.view_icon_button}
                    onClick={openInNewTab}
                  >
                    {" "}
                    Show External Feedbacks{" "}
                  </div>
                  <div
                    onClick={togglechecklist}
                    className={styles.view_icon_button}
                  >
                    {" "}
                    Checklist Form{" "}
                  </div>
                </div>
              )}
            </motion.div>
            {showApprovalForm && (
              <div id="approval_form" className={styles.approval_form}>
                <div
                  id="view_icon"
                  className={styles.icon_section}
                  onClick={handleBackButtonClick}
                >
                  <IoArrowBackSharp className={styles.icon} />
                </div>
                <div className={styles.label_section}>
                  Are you sure you want to approve this file?
                </div>
                <div className={styles.button_section}>
                  <div
                    onClick={handleBackButtonClick}
                    className={common.secondaryBtn}
                  >
                    Cancel
                  </div>

                  <button
                    onClick={() => approveFunction(currentpoid)}
                    className={common.primaryBtn}
                  >
                    {buttonDisabled ? "Processing..." : "Approve"}
                  </button>
                </div>
              </div>
            )}
            {showParkForm && (
              <div id="park_form" className={styles.approval_form}>
                <div
                  id="view_icon"
                  className={styles.icon_section}
                  onClick={handleBackButtonClick}
                >
                  <IoArrowBackSharp className={styles.icon} />
                </div>
                <div className={styles.label_section}>
                  Are you sure you want to park this file?
                </div>
                <div className={styles.button_section}>
                  <div
                    onClick={handleBackButtonClick}
                    className={common.secondaryBtn}
                  >
                    Cancel
                  </div>
                  <div
                    onClick={() => parkFunction(currentpoid)}
                    className={common.primaryBtn}
                  >
                    Park Job
                  </div>
                </div>
              </div>
            )}
            {showFeedbackForm && (
              <div id="feedback_form" className={styles.feedback_form}>
                <div className={styles.header_section}>
                  <div className={styles.title}>Feedback Form</div>
                  <HiDotsVertical
                    className={styles.icon}
                    onClick={handleViewIconClick}
                  />
                </div>

                <div className={styles.category_section}>
                  <div className={styles.category_section_1}>
                    <div className={styles.label_section}>Category </div>
                    <div className={styles.image_button}>
                      <select
                        className={styles.select}
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select Category</option>
                        <option value="MeshBuild">Mesh Build</option>
                        <option value="Backface">Backface</option>
                        <option value="Gaps">Gaps</option>
                        <option value="ColorAB">ColorAB</option>
                        <option value="ColorL">ColorL</option>
                        <option value="detail_count">Detail Count</option>
                        <option value="detail_placement">
                          Detail Placement
                        </option>
                        <option value="detail_type">Detail Type</option>
                        <option value="Metalness">Metalness</option>
                        <option value="pattern_direction">
                          Pattern Direction
                        </option>
                        <option value="pattern_scale">Pattern Scale</option>
                        <option value="pattern_type">Pattern Type</option>
                        <option value="roughness">Roughness</option>
                        <option value="height_map">Height Map</option>
                        <option value="refraction">Index of refraction</option>
                        <option value="normal_map">Normal Map</option>
                        <option value="shader">Shader</option>
                        <option value="transparency">Transparency</option>
                        <option value="orientation">Orientation</option>
                        <option value="pivot_point">Pivot Point</option>
                        <option value="style_guide">Style Guide</option>
                        <option value="scale">Scale</option>
                        <option value="major_shape_elements">
                          Major Shape Elements
                        </option>
                        <option value="shape_silhouette">
                          Shape Silhouette
                        </option>
                        <option value="complete_rework">Complete Rework</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.category_section_1}>
                    <div className={styles.label_section}>Image</div>
                    <div className={styles.image_button}>
                      <input type="file" name="" onChange={handleImageChange} />
                    </div>
                  </div>
                  <div className={styles.category_section_1}>
                    <div className={styles.label_section}>Comment</div>
                    <div className={styles.image_button}>
                      <input
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Enter your comments here"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={styles.more_category_section}
                  onClick={handleFeedbackSubmit}
                >
                  <div className={styles.icon_section}>
                    <CiCirclePlus />
                  </div>
                  <div className={styles.label_section}>
                    Upload image & add more feedback
                  </div>
                </div>
                {handleFormRender()}
                <div className={styles.button_section}>
                  <div
                    onClick={handleBackButtonClick}
                    className={common.secondaryBtn}
                  >
                    Cancel
                  </div>
                  <div
                    className={common.primaryBtn}
                    onClick={() => submitAllFeedbacks(currentpoid)}
                  >
                    Reject Job
                  </div>
                </div>
              </div>
            )}
            {isSupportiveVisible && renderSupportiveContent()}

            {ispreviousFeedbacks && renderFeedbackContent()}
          </div>

          <ToastContainer />
        </motion.div>
      </div>
    </IqaLayout>
  );
}

export default IQA_Component;
