import React, { useState } from "react";
import styles from "./Checklist.module.scss";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

function Checklist() {
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams] = useSearchParams();
  const poid = searchParams.get("poid");
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const uncheckedBoxes = Array.from(checkboxes).some(
      (checkbox) => !checkbox.checked
    );

    setIsChecked(!uncheckedBoxes);
  };

  const handleSubmit = async () => {
    if (isChecked) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://www.api.advflr.com/studio/iqa/mark-checklist.php",
          { poid }
        );
        console.log(response);

        if (response.data.success) {
          window.close();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please check all the checkboxes.");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodySection}>
        <div className={styles.card}>
          <div className={styles.mainCategory}>
            <div>
              <input type="checkbox" onChange={handleCheckboxChange} />
            </div>

            <div className={styles.columnSection}>
              <div className={styles.title}>Common Checklist</div>
              <div className={styles.subtitle}>
                This is the common checklists
              </div>
            </div>
          </div>

          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Mannequin Comparison</p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Dimension Matching </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Pivot </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Orientation </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Shader </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Color </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Roughness </p>
          </div>

          <div className={styles.mainCategory}>
            <div>
              <input type="checkbox" onChange={handleCheckboxChange} />
            </div>

            <div className={styles.columnSection}>
              <div className={styles.title}>Mesh</div>
              <div className={styles.subtitle}>
                This is the checklists for mesh
              </div>
            </div>
          </div>

          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Mesh Overlap</p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Backface </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Gap </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Proportion </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Hard Edges </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Silhouette </p>
          </div>

          <div className={styles.mainCategory}>
            <div>
              <input type="checkbox" onChange={handleCheckboxChange} />
            </div>

            <div className={styles.columnSection}>
              <div className={styles.title}>Pattern</div>
              <div className={styles.subtitle}>
                This is the checklists for Pattern
              </div>
            </div>
          </div>

          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Pattern Tiling </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Pattern Scale </p>
          </div>
          <div className={styles.subline}></div>
          <div className={styles.subCategory}>
            <input type="checkbox" onChange={handleCheckboxChange} />
            <p>Pattern Direction </p>
          </div>

          <div className={styles.buttonSection}>
            <button className={styles.secondary}>Cancel</button>
            <button className={styles.primary} onClick={handleSubmit}>
              {isLoading ? "Loading..." : "Completed"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checklist;
