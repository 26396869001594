import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Lead3d.module.scss'
import { FaGear, FaBell, FaExpand } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Lead3d() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    // Fetch user data from the API
    const user = 'your_username'; // Replace with your actual username or get it dynamically
    axios.get(`http://localhost/auth/artist.php?${user}`)
      .then(response => {
        // Handle the response and set the user data in state
        setUserData(response.data.data); // Assuming the API response has a 'data' property
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  return (
    <div>
        <div className={styles.container}>
            
            <div className={styles.topsection}>
              <div className={styles.leftsection}>
                <img src="/logo.webp" alt="" />
                <input type="text" placeholder='Search Here' />

              </div>
              <div className={styles.rightsection}>
                <div className={styles.profile}>
                  <img src="/assets/profile/imman.webp" alt="" />
                  <div className={styles.details}>
                    <p className={styles.name}>{userData.name} - {userData.emp_id}</p>
                    <p className={styles.title}>{userData.designation}</p>
                  </div> 
                </div>

                <div className={styles.breakMenu}>
                  {/* Mega menu content: Break 1, Lunch, Break 2, Logout */}
                  <ul>
                    <li>Break - 1</li>
                    <li>Lunch</li>
                    <li>Meeting</li>
                    <li>Break - 2</li>
                    <li>Logout</li>
                  </ul>
                </div>

                <FaGear className={styles.icon} />
                <FaBell className={styles.icon}/>
                <FaExpand className={styles.icon}/>
              </div>
            </div>
            <ul className={styles.bottomsection}>
              <Link to="/"> 
                <li>Home</li>
              </Link>
              

              <li className={styles.megaMenu}>List Jobs
                <div className={styles.megaContent}>
                  
                  <Link to="/list-jobs/cc">
                    <li>CC</li>
                  </Link>
                  <li>Self Assigner</li>
                </div>
              </li>
              
              <li className={styles.megaMenu}>Leaves Management
                <div className={styles.megaContent}>
                  <li>Apply Leave</li>
                  <li>Available Leaves</li>
                </div>
              </li>

              <li className={styles.megaMenu}>Utilities
                <div className={styles.megaContent}>
                  <li>File Assigner</li>
                  <li>File Reassigner</li>
                  <li>Clarification Updater</li>
                  <li>Clarification Resolver</li>
                  <li>File Reassigner</li>
                  <li>Status Changer</li>
                </div>
              </li>

              <li className={styles.megaMenu}>My Team
                <div className={styles.megaContent}>
                  <li>Shift Change</li>
                  <Link to="/audit/late-logins/">
                    <li>Late Login Approval</li>
                  </Link>
                  
                  <li>Pending Leaves Approval</li>
                </div>
              </li>

              <li className={styles.megaMenu}>
                Report
                <div className={styles.megaContent}>
                  <li>IPR Report</li>
                  <li>Team Files Report</li>
                  <li>Team Login Report</li>
                </div>
              </li>

              <li className={styles.megaMenu}>Support
                <div className={styles.megaContent}>
                  <li>Raise Query</li>
                  <li>Awaiting Query</li>
                  <li>Pending Query</li>
                </div>
              </li>

            </ul>

        </div>
    </div>
  )
}

export default Lead3d