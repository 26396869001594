import React, { useState } from "react";
import { MdOutlineHome } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { LuArrowLeftToLine, LuArrowRightToLine } from "react-icons/lu";
import { IoCalendarNumberOutline, IoSettingsOutline } from "react-icons/io5";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";

function SideNavbar() {
  const { selectedMenu, setSelectedMenu } = useStore();
  const [shrinkMenu, setShrinkMenu] = useState(false);
  const navigate = useNavigate();

  const handleHomeMenu = () => {
    setSelectedMenu("home");
    navigate("/list-jobs/iqa");
  };

  const handlereportsMenu = () => {
    setSelectedMenu("reports");
    navigate("/reports");
  };

  const shrinkButton = () => {
    setShrinkMenu(!shrinkMenu);
    // alert("Shrink Button Clicked");
  };

  const handleleaveMenu = () => {
    setSelectedMenu("leave-management");
    window.open("https://3dstudio.advertflair.com/leaveRequest.html", "_blank");
  };

  const handlesettingsMenu = () => {
    setSelectedMenu("settings");
  };
  return (
    <div
      className={`bg-white h-[100vh]  flex flex-col justify-between  ${
        shrinkMenu ? "w-[64px] p-2" : "w-[340px] p-6"
      }`}
    >
      <div className="flex flex-col gap-4">
        <div>
          <img
            className={` ${shrinkMenu ? "h-12 ml-0" : "h-12 ml-4"}`}
            src={`/assets/logo/${shrinkMenu ? "logoSmall" : "logoFull"}.png`}
            alt=""
          />
        </div>

        <div className="flex flex-col gap-1">
          <div
            onClick={handleHomeMenu}
            className={`flex gap-4 items-center  rounded-md cursor-pointer hover:bg-[#e3e3f0] ${
              shrinkMenu ? "px-3 py-2" : "px-6 py-4"
            }   ${selectedMenu === "home" ? "bg-[#e3e3f0] font-bold" : ""}`}
          >
            <MdOutlineHome className="text-2xl" />
            <p className={`${shrinkMenu ? "hidden" : "flex"}`}>
              {!shrinkMenu && <p>Home</p>}
            </p>
          </div>
          <div
            onClick={handleleaveMenu}
            className={`flex gap-4 items-center  rounded-md cursor-pointer hover:bg-[#e3e3f0] ${
              shrinkMenu ? "px-3 py-2" : "px-6 py-4"
            } ${
              selectedMenu === "leave-management"
                ? "bg-[#e3e3f0] font-bold"
                : ""
            }`}
          >
            <IoCalendarNumberOutline className="text-2xl" />
            <p className={`${shrinkMenu ? "hidden" : "flex"}`}>
              {!shrinkMenu && <p>Leave Management</p>}
            </p>
          </div>
          <div
            onClick={handlereportsMenu}
            className={`flex gap-4 items-center  rounded-md cursor-pointer hover:bg-[#e3e3f0] ${
              shrinkMenu ? "px-3 py-2" : "px-6 py-4"
            } ${selectedMenu === "reports" ? "bg-[#e3e3f0] font-bold" : ""}`}
          >
            <TbReport className="text-2xl" />
            {!shrinkMenu && <p>Reports</p>}
          </div>

          <div
            onClick={handlesettingsMenu}
            className={`flex gap-4 items-center rounded-md cursor-pointer hover:bg-[#e3e3f0] ${
              shrinkMenu ? "px-3 py-2" : "px-6 py-4"
            } ${selectedMenu === "settings" ? "bg-[#e3e3f0] font-bold" : ""}`}
          >
            <IoSettingsOutline className="text-2xl" />
            {!shrinkMenu && <p>Settings</p>}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-[1px] w-full bg-black/20"></div>
        <div className="w-full flex justify-end">
          {shrinkMenu && (
            <LuArrowRightToLine
              className="text-2xl cursor-pointer"
              onClick={shrinkButton}
            />
          )}

          {!shrinkMenu && (
            <LuArrowLeftToLine
              className="text-2xl cursor-pointer"
              onClick={shrinkButton}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SideNavbar;
