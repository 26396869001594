import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./navbar.module.scss";
import { FaGear, FaBell, FaExpand } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
function IQA() {
  const [userData, setUserData] = useState([""]);
  const baseURL = "https://54.148.252.184";
  useEffect(() => {
    const user = Cookies.get("username");
    axios
      .get(`https://54.148.252.184/auth/profile.php?artist=${user}`)
      .then((response) => {
        const success = response.data.success;
        const data = response.data.data;
        if (success) {
          setUserData(data);
        }
        // Handle the response and set the user data in state
        // Assuming the API response has a 'data' property
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  return (
    <div className="bg-red-400">
      <div className={styles.container}>
        <ul className={styles.bottomsection}>
          <Link to="/">
            {" "}
            <li>Home</li>
          </Link>
          <Link to="/list-jobs/">
            {" "}
            <li>List Jobs</li>{" "}
          </Link>
          <Link to="/leave-management">
            {" "}
            <li>Leave Management</li>{" "}
          </Link>
          <Link to="/Utilities">
            {" "}
            <li>Utilities</li>{" "}
          </Link>
          <Link to="/my-team">
            {" "}
            <li>My Team</li>{" "}
          </Link>
          <Link to="/reports">
            {" "}
            <li>Reports</li>{" "}
          </Link>
          <Link to="/support">
            {" "}
            <li>Support</li>{" "}
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default IQA;
