import React from "react";
import Header from "../components/common/Header";
import SideNavbar from "../components/common/SideNavbar";
import BreakModel from "../components/common/BreakModel";

function IqaLayout({ children }) {
  return (
    <div className="flex gap-0 h-[100vh]">
      <div>
        <SideNavbar />
      </div>
      <div className="w-full flex flex-col gap-3 py-4">
        <Header />
        {children}
        <BreakModel />
      </div>
    </div>
  );
}

export default IqaLayout;
