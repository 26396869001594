import React, { useState } from "react";
import styles from "./Reports.module.scss";
import common from "../../../styles/common.module.scss";
import IQA from "../../../navbar/IQA";
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import IqaLayout from "../../../layouts/IqaLayout";

function ProductionReport() {
  const [selectedOption, setSelectedOption] = useState("");
  const [poidValue, setPoidValue] = useState("");
  const [reportData, setReportData] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [processName, setProcessName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleProcessChange = (event) => {
    setProcessName(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handlePoidInputChange = (event) => {
    setPoidValue(event.target.value);
  };

  const handleDownloadCSV = () => {
    if (reportData) {
      // Create headers and data for CSV
      const headers = [
        "No",
        "Partner ID",
        "Process Name",
        "Status",
        "Executor",
        "Start Time",
        "End Time",
      ];
      const csvContent =
        `${headers.join(",")}\n` +
        reportData.data
          .map(
            (item, index) =>
              `${index + 1},${item.partnerid},${item.process_name},${
                item.status
              },${item.executor},${item.start_time},${item.end_time || ""}`
          )
          .join("\n");

      // Create a download link and trigger the click event
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "report.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleGetReport = () => {
    if (!loading) {
      // Check if no request is already in progress
      setLoading(true); // Set loading state to true

      if (selectedOption === "POID") {
        if (!poidValue.trim()) {
          toast.error("Please enter a POID");
          return;
        }
        axios
          .get(
            `https://3dstudio.advertflair.com/report/production-log.php?partner_id=${poidValue}`
          )
          .then((response) => {
            setReportData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching POID report:", error);
          })
          .finally(() => {
            setLoading(false); // Reset loading state whether the request succeeds or fails
          });
      } else if (selectedOption === "PROCESS") {
        axios
          .get(
            `https://3dstudio.advertflair.com/report/production-log.php?process_name=${processName}&starttime=${startDate}&endtime=${endDate}`
          )
          .then((response) => {
            setReportData(response.data);
          })
          .catch((error) => {
            toast.error("Error fetching PROCESS report. Please try again.");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <IqaLayout>
      <div>
        <Helmet>
          <title>Reports - Production Report Page</title>
          <link rel="icon" href="/assets/logo/favicon.png" />
        </Helmet>

        <div className={styles.container}>
          <div className={styles.title}>
            <p> I want the report by </p>
            <select onChange={handleOptionChange} value={selectedOption}>
              <option value="" defaultChecked>
                Choose here
              </option>
              <option>POID</option>
              <option>PROCESS</option>
            </select>
          </div>

          {selectedOption === "POID" && (
            <div className={styles.inputSection}>
              <input
                type="text"
                placeholder="Please Enter POID"
                value={poidValue}
                onChange={handlePoidInputChange}
              />
              <button className={common.primaryBtn} onClick={handleGetReport}>
                {loading ? "Processing" : "View Report"}
              </button>
              {reportData && (
                <button
                  className={common.secondaryBtn}
                  onClick={handleDownloadCSV}
                >
                  Download as CSV
                </button>
              )}
            </div>
          )}

          {selectedOption === "PROCESS" && (
            <div className={styles.dropdownSection}>
              <select
                className={styles.process}
                onChange={handleProcessChange}
                value={processName}
              >
                <option value="" defaultChecked>
                  Choose here
                </option>
                <option>UPLOAD</option>
                <option>IQA</option>
                <option>FINAL REVIEW</option>
                <option>SQT</option>
                <option>EQA</option>
                <option>OVERALL</option>
              </select>

              <div className={styles.dateSection}>
                <label htmlFor="">Start Date</label>
                <input
                  type="date"
                  onChange={handleStartDateChange}
                  value={startDate}
                  placeholder="Choose Start Date"
                />
              </div>

              <div className={styles.dateSection}>
                <label htmlFor="">End Date</label>
                <input
                  type="date"
                  onChange={handleEndDateChange}
                  value={endDate}
                  placeholder="Choose End Date"
                />
              </div>

              <button className={common.primaryBtn} onClick={handleGetReport}>
                {loading ? "Processing" : "View Report"}
              </button>
              <button
                className={common.secondaryBtn}
                onClick={handleDownloadCSV}
              >
                Download as CSV
              </button>
            </div>
          )}

          {reportData && (
            <div className={styles.report}>
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>POID</th>
                    <th>Process Name</th>
                    <th>Status</th>
                    <th>Executor</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.data.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.partnerid}</td>
                      <td>{item.process_name}</td>
                      <td>{item.status}</td>
                      <td>{item.executor}</td>
                      <td>{item.end_time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <ToastContainer />
      </div>
    </IqaLayout>
  );
}

export default ProductionReport;
